import * as React from "react";

import Image from "components/media/Image";
import Video from "components/media/Video";
import PropTypes from "lib/propTypes";

function Asset({
  autoPlay,
  backgroundColor,
  component,
  controls,
  image,
  loop,
  mobileSources,
  muted,
  poster,
  sources,
}) {
  if (component === `Image`)
    return <Image alt={image.alt} src={image.filename} />;
  return (
    <Video
      playsInline={false}
      poster={poster.filename}
      {...{
        autoPlay,
        backgroundColor,
        controls,
        loop,
        mobileSources,
        muted,
        sources,
      }}
    />
  );
}

Asset.propTypes = {
  autoPlay: PropTypes.bool,
  backgroundColor: PropTypes.color,
  component: PropTypes.oneOf([`Image`, `Video`]).isRequired,
  controls: PropTypes.oneOf([``, `all`, `unmute`]),
  image: PropTypes.asset,
  loop: PropTypes.bool,
  mobileSources: PropTypes.arrayOf(PropTypes.asset),
  muted: PropTypes.bool,
  poster: PropTypes.asset,
  sources: PropTypes.arrayOf(PropTypes.asset),
};

Asset.defaultProps = {
  autoPlay: false,
  backgroundColor: null,
  controls: false,
  image: null,
  loop: false,
  mobileSources: [],
  muted: false,
  poster: null,
  sources: [],
};

export default Asset;
