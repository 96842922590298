import * as React from "react";
import { useRef } from "react";
import { Box, Button, Flex } from "theme-ui";
import { useIntersection, useMedia } from "react-use";

import Image from "components/media/Image";
import ArrowDownIcon from "images/icons/arrow-down.svg";
import PropTypes from "lib/propTypes";

const BAR_HEIGHT = 48;
const ARROW_SIZE = 18;
const BAR_PADDING = 15;
const BAR_OFFSCREEN = ARROW_SIZE + BAR_PADDING;
const COVER_BACKGROUND = {
  position: `absolute`,
  top: 0,
  left: 0,
  width: `100%`,
  height: `100%`,
  zIndex: -1,
};

function ImageBand({
  arrowColor,
  headerBg,
  headerColor,
  headerLogo,
  isExpanded,
  title,
  toggleProps,
  uid,
}) {
  const isTouchDevice = useMedia(`(hover: none)`);

  const headerRef = useRef(null);
  const { isIntersecting } =
    useIntersection(headerRef, { rootMargin: `-50%` }) ?? {};

  return (
    <header ref={headerRef} data-header-uid={uid}>
      <Button
        variant="invisible"
        className={
          isTouchDevice && isIntersecting && !isExpanded ? `in-view` : null
        }
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `stretch`,
          position: `relative`,
          zIndex: 0,
          overflow: `hidden`,
          width: `100%`,
          height: [192, 240],
          bg: headerColor,
          "&:focus-within": {
            zIndex: 1,
          },
        }}
        {...toggleProps}
      >
        {headerBg.filename && (
          <Image
            alt={headerBg.alt}
            src={headerBg.filename}
            sx={{
              ...COVER_BACKGROUND,
              objectFit: `cover`,
              transition: `expandingHeader`,
              "*:is(:focus-visible, :hover, .in-view) > &": {
                transform: `scale(133.33%)`,
              },
            }}
          />
        )}
        <Box
          sx={{
            ...COVER_BACKGROUND,
            bg: `black`,
            opacity: 0.4,
            transition: `expandingHeader`,
            "[aria-expanded=true] > &": {
              opacity: 0.5,
            },
            "*:is(:focus-visible, :hover, .in-view) > &": {
              opacity: 0.6,
            },
          }}
        />
        {headerLogo.filename && (
          <Image
            alt={title ?? headerLogo.alt}
            src={headerLogo.filename}
            sx={{
              m: `auto`,
              transition: `expandingHeader`,
              transform: [
                `scale(90%) translateY(${BAR_OFFSCREEN / 2}px)`,
                `scale(128%) translateY(${BAR_OFFSCREEN / 2}px)`,
              ],
              "*:is(:focus-visible, :hover, .in-view) > &": {
                transform: [`scale(75%)`, `none`],
              },
              "*:not(:focus-visible, :hover, .in-view, [aria-expanded=true]) > &":
                {
                  filter: `grayscale(100%) brightness(500%)`,
                },
            }}
          />
        )}
        <Flex
          sx={{
            justifyContent: `center`,
            alignItems: `center`,
            height: BAR_HEIGHT,
            bg: headerColor,
            transition: `expandingHeader`,
            "*:not(:focus-visible, :hover, .in-view, [aria-expanded=true]) > &":
              {
                transform: `translateY(${BAR_OFFSCREEN}px)`,
              },
          }}
        >
          <Box
            as={ArrowDownIcon}
            sx={{
              width: ARROW_SIZE,
              height: ARROW_SIZE,
              color: arrowColor,
              transition: `expandingHeader`,
              transitionTimingFunction: `ease-out`,
              "[aria-expanded=true] &": {
                transform: `rotate(-180deg)`,
              },
            }}
          />
        </Flex>
      </Button>
    </header>
  );
}

ImageBand.propTypes = {
  arrowColor: PropTypes.oneOf([`black`, `white`]),
  headerBg: PropTypes.asset,
  headerColor: PropTypes.color,
  headerLogo: PropTypes.asset,
  isExpanded: PropTypes.bool,
  title: PropTypes.string,
  toggleProps: PropTypes.shape(),
  uid: PropTypes.string,
};

ImageBand.defaultProps = {
  arrowColor: `white`,
  headerBg: null,
  headerColor: `black`,
  headerLogo: null,
  isExpanded: false,
  title: null,
  toggleProps: null,
  uid: null,
};

export default ImageBand;
