import { useCollapse } from "react-collapsed";
import useScrollTo from "lib/useScrollTo";

export default ({ isExpanded, uid }) => {
  const { duration, scrollTo } = useScrollTo();

  return useCollapse({
    duration,
    isExpanded,
    onTransitionStateChange: (stage) => {
      if (stage !== `expandStart`) return;

      const prevCaseStudies = document.querySelectorAll(`[data-header-uid]`);

      let prevCaseStudyHeights = 0;
      Array.from(prevCaseStudies).some((element) => {
        if (element.getAttribute(`data-header-uid`) === uid) return true;
        prevCaseStudyHeights += element.getBoundingClientRect().height;
        return false;
      });

      scrollTo(
        window.scrollY +
          prevCaseStudies[0].getBoundingClientRect().top +
          prevCaseStudyHeights
      );
    },
  });
};
