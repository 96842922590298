import * as React from "react";
import { Box, Divider, Flex } from "theme-ui";

import Column from "components/layout/Column";
import Container from "components/layout/Container";
import Row from "components/layout/Row";
import Image from "components/media/Image";
import Carousel from "components/sections/Carousel";
import Heading from "components/typography/Heading";
import Text from "components/typography/Text";
import PropTypes from "lib/propTypes";

function Project({
  blok: { awards, description, logo, media, partners, title },
}) {
  return (
    <Container as="section" thin mt={[`xxs`, `xxxl`]} mb={[`xl`, `xxxl`]}>
      {!!media.length && (
        <>
          <Carousel assets={media} sx={{ mb: [`xs`, 0] }} />
          <Divider mt="l" mb="s" sx={{ display: [`none`, `block`] }} />
        </>
      )}
      <Row>
        <Column span={[6, awards.length ? 4 : 6]}>
          {title && (
            <Flex sx={{ justifyContent: `space-between` }}>
              <Heading as="h3" variant="h4" sx={{ fontWeight: `bold` }}>
                {title}
              </Heading>
              {!!awards.length && (
                <Image
                  alt={logo.alt}
                  src={logo.filename}
                  ml="xs"
                  sx={{
                    display: [`block`, `none`],
                    height: 40,
                  }}
                />
              )}
            </Flex>
          )}
          <Text variant="l">{description}</Text>
          <Text as="p" variant="m-bold">
            {partners && partners.trim().replace(/\n+/g, ` + `)}
          </Text>
        </Column>
        {!!awards.length && (
          <Column span={[6, 2]}>
            <Image
              alt={logo.alt}
              src={logo.filename}
              mb="xs"
              sx={{ display: [`none`, `block`] }}
            />
            <Heading as="h4" variant="overline2">
              Awards
            </Heading>
            <Box as="ul" variant="lists.unstyled" sx={{ my: 0 }}>
              {awards.map(
                ({ _uid, category: awardCategory, title: awardTitle }) => (
                  <Text key={_uid} as="li" variant="l">
                    {awardTitle}
                    <Box as="br" sx={{ display: [`none`, `block`] }} />
                    <Text as="span" variant="m">
                      <span sx={{ display: [`inline`, `none`] }}>
                        {` `}&mdash;{` `}
                      </span>
                      {awardCategory}
                    </Text>
                  </Text>
                )
              )}
            </Box>
          </Column>
        )}
      </Row>
    </Container>
  );
}

Project.propTypes = {
  blok: PropTypes.shape({
    awards: PropTypes.arrayOf(
      PropTypes.shape({
        _uid: PropTypes.string,
        category: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    description: PropTypes.richText,
    logo: PropTypes.asset,
    media: PropTypes.arrayOf(PropTypes.asset),
    partners: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Project;
