import * as React from "react";
import { Box, Button } from "theme-ui";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

import Asset from "components/media/Asset";
import ArrowLeftIcon from "images/icons/arrow-left.svg";
import ArrowRightIcon from "images/icons/arrow-right.svg";
import IndicatorIcon from "images/icons/indicator.svg";
import PropTypes from "lib/propTypes";

function Arrow({ direction, mode, ...props }) {
  return (
    <Button variant={`arrow-${direction}-${mode}`} {...props}>
      {direction === `left` ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </Button>
  );
}

Arrow.propTypes = {
  direction: PropTypes.oneOf([`left`, `right`]).isRequired,
  mode: PropTypes.oneOf([`light`, `dark`]).isRequired,
};

function Indicator({
  indicatorProps: [onClick, isSelected, index, label],
  mode,
}) {
  let variant = `indicator-${mode}`;
  if (isSelected) variant += `-active`;
  return (
    <li sx={{ display: `inline-block` }}>
      <Button
        variant={variant}
        aria-label={`${label} ${index}`}
        onClick={onClick}
      >
        <IndicatorIcon />
      </Button>
    </li>
  );
}

Indicator.propTypes = {
  indicatorProps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ])
  ).isRequired,
  mode: PropTypes.oneOf([`light`, `dark`]).isRequired,
};

function RenderItem(
  { props: { autoPlay, ...props }, ...item },
  { isSelected }
) {
  return <item.type autoPlay={isSelected && autoPlay} {...props} />;
}

RenderItem.propTypes = {
  props: PropTypes.shape({
    autoPlay: PropTypes.bool,
  }).isRequired,
};

function PreparedCarousel({ mode, ...props }) {
  return (
    <ReactCarousel
      renderArrowPrev={(onClick) => (
        <Arrow direction="left" mode={mode} onClick={onClick} />
      )}
      renderArrowNext={(onClick) => (
        <Arrow direction="right" mode={mode} onClick={onClick} />
      )}
      renderIndicator={(...indicatorProps) => (
        <Indicator indicatorProps={indicatorProps} mode={mode} />
      )}
      renderItem={RenderItem}
      showStatus={false}
      showThumbs={false}
      {...props}
    />
  );
}

PreparedCarousel.propTypes = {
  mode: PropTypes.oneOf([`light`, `dark`]),
};

PreparedCarousel.defaultProps = {
  mode: `light`,
};

function Carousel({ assets, ...props }) {
  const Component = assets.length > 1 ? PreparedCarousel : Box;
  return (
    <Component {...props}>
      {assets.map(({ _uid, ...assetProps }) => (
        <Asset key={_uid} {...assetProps} />
      ))}
    </Component>
  );
}

Carousel.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
    })
  ),
};

Carousel.defaultProps = {
  assets: [],
};

export default Carousel;
