import * as React from "react";
import { Box } from "theme-ui";

import Container from "components/layout/Container";
import Text from "components/typography/Text";
import BackToTop from "components/BackToTop";
import PropTypes from "lib/propTypes";
import ImageBand from "./ImageBand";
import Project from "./Project";
import useCaseStudyCollapse from "./useCaseStudyCollapse";

function CollapsibleCaseStudy({
  blok: {
    _uid: uid,
    arrowColor,
    backToTop,
    description,
    headerBg,
    headerColor,
    headerLogo,
    projects,
    title,
  },
  isExpanded,
  onClick,
}) {
  const { getCollapseProps, getToggleProps } = useCaseStudyCollapse({
    isExpanded,
    uid,
  });

  return (
    <>
      <ImageBand
        toggleProps={getToggleProps({ onClick })}
        {...{
          arrowColor,
          headerBg,
          headerColor,
          headerLogo,
          isExpanded,
          title,
          uid,
        }}
      />
      <Box bg="background" sx={{ overflow: `hidden` }} {...getCollapseProps()}>
        <Box pt={[0, `xxxl`]} pb={backToTop ? 0 : [0, `xxxl`]}>
          <Container thin>
            <Text
              as="section"
              textAlign="center"
              sx={{ mt: [`xl`, 0], mb: [`xl`, `xxxl`] }}
            >
              {description}
            </Text>
          </Container>
          {projects.map(({ _uid, ...blok }) => (
            <Project key={_uid} blok={blok} />
          ))}
        </Box>
        {backToTop && <BackToTop variant="transparent" onClick={onClick} />}
      </Box>
    </>
  );
}

CollapsibleCaseStudy.propTypes = {
  blok: PropTypes.shape({
    _uid: PropTypes.string,
    arrowColor: PropTypes.oneOf([`black`, `white`]),
    backToTop: PropTypes.bool,
    description: PropTypes.richText,
    headerBg: PropTypes.asset,
    headerColor: PropTypes.color,
    headerLogo: PropTypes.asset,
    projects: PropTypes.arrayOf(PropTypes.blok),
    title: PropTypes.string,
  }).isRequired,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

CollapsibleCaseStudy.defaultProps = {
  isExpanded: false,
  onClick: null,
};

export default CollapsibleCaseStudy;
